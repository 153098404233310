

// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Include functions first
@import "../node_modules/bootstrap/scss/functions";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Customize some defaults
$body-color: #3e3e3e;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;
$display-font-weight: 400;
$font-family-sans-serif:      Poppins, OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       Poppins, OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$navbar-padding-x: 2em;
$navbar-padding-y: 1em;
$nav-link-font-weight: 400;
$nav-link-font-size: 1vw;
$enable-rounded: true;
$btn-color: #5B0428;
$link-color: #000000;
$font-size-base: 1rem;
$h1-font-size: 2.25rem;
$h2-font-size: 2rem;
$headings-color: #000000;
@import "../node_modules/bootstrap/scss/bootstrap";
@import "icon-list";
$mynewcolor:#5B0428;
.btn-primary {
  @include button-variant($mynewcolor, darken($mynewcolor, 7.5%), white, lighten($mynewcolor,5%), lighten($mynewcolor, 10%), white);
}
.btn-outline-primary {
  @include button-outline-variant($mynewcolor, #222222, white, $mynewcolor);
}
//
// Custom styles
//

h2{
  font-weight: 600;
}

h3{
    font-weight: 600;
    font-size: 1.5rem;
}

 .search-form {
     max-width: 175px;
     padding: 1rem;
     display: flex;
   }
.search-button{
  display: none;
}
.ultrus_logo{
  min-width: 25vw;
  @include media-breakpoint-up(md) {
    min-width: 21vw;
  }
  @include media-breakpoint-up(lg) {
    min-width: 17vw;
  }
  @include media-breakpoint-up(xl) {
    min-width: 9vw;
  }
}
#search-input2{
  width: 100% !important;
}
.search-button .dropdown-menu{
  width:200px;
}
@include media-breakpoint-down(lg) {
  .search-button{
    display: flex;
    margin-right: 5rem;
  }
}
@media(max-width: 351px){
  .search-button{
    display: none;
  }
}
@include media-breakpoint-down(lg) {
  .search-form {
    display: none;
  }
}


@include media-breakpoint-down(lg){
  .nav-link {
    font-size: 3vw;
  }
}
.nav-link { color: #000000; }
.ultrus_page_title {

  padding-left: .5em;
  font-weight: 600;
  padding-top: .5em;
  margin-left: 1em;
  border-left: 1px solid #C8C8C8;
  @include media-breakpoint-down(sm) {
    padding-left: .15rem;
    margin-left: 0;
    font-size: .75rem;
  }

}
.navbar-brand {
  margin-right: 0;
}
.ultrus_page_title h3{
  @include media-breakpoint-down(md) {
    font-size: 1.2rem;
  }
  @include media-breakpoint-up(md){
    font-size: 1.75rem;
  }
}
.navbar {

  border-bottom: 1px solid #C8C8C8;
  box-shadow: 0 4px 2px -2px #C8C8C8;
  @include media-breakpoint-down(md) {
    padding-left: .25rem;
    padding-right: .25rem;
  }
  @include media-breakpoint-up(lg) {
    padding-left: .7rem;
    padding-right: .7rem;

  }
  @include media-breakpoint-up(xl) {
    padding-left: 2rem;
    padding-right: 2rem;

  }
}
.navbar-nav{
  padding-left: 1.5rem;
}
#search-input{
  max-height: 32px;
}
#searchbtn{
  max-height: 29px;
}
#searchbtn:hover{
  color: #6c757d;
}
.btn-rounded{
    border-radius: 120px;
}
@include media-breakpoint-down(lg) {
  .nav-sign-in {
    display: none;
  }
}
.nav-sign-in{
  --bs-btn-bg: #5B0428;
  --bs-btn-border-color: #5B0428;
  --bs-btn-hover-bg: #5B0428;
  --bs-btn-active-bg: #5B0428;
    --bs-btn-active-border-color: #5B0428;
  --bs-btn-disabled-bg: #5B0428;
    --bs-btn-disabled-border-color: #5B0428;
  font-size: .98rem;
    font-weight: 500;
  min-width: 79px;
  max-height: 38px;
}
.ulbtn-link{
    color: #5B0428;
    font-size: 16px;
    font-weight: 500;
  text-decoration: none;
  margin-left: 1em;
}
.product-link{
  text-decoration: none;
}
#footer {
  background: url(../img/patterns/body-bg.jpg) repeat #000;
  padding: 30px 0px;
  text-shadow: 0px 1px 0px #000;
  color: #CCCCCC;
}
#footer h6{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    color: #CCCCCC;
}
#footer h5{
  font-weight: 600;
  line-height: 1.3em;
  font-style: normal;
  margin-bottom: 15px;
  font-size:14px
}
#footer p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
  text-shadow: 0px 1px 0px #000;
  color: #CCCCCC;
}
.footer-text {
  font-size: 0.70em;
  line-height: 1.2em;
}
.list-unstyled li {
  margin-bottom: 20px;
  background: none;
  list-style-type: none;
  display: block;
}
.list-unstyled-grid li {
  margin-bottom: 4px;
  background: none;
  list-style-type: circle;
}
.list-unstyled img {
  margin-right: 10px;
  float: left;
}
.list-unstyled span .trans{
  opacity: 0;
}
.list-unstyled li a{
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}
.bi-r-circle{
  vertical-align: super;
  font-size: 0.75rem;
}
.card-img-height{
  max-height: 149px;
  overflow: hidden;
}
.card-text-space{
  min-height: 145px;
}
.card-text{
    font-size: 16px;
    font-weight: 400;
}
.card a{
  text-decoration: none;
}
.carousel-control-prev{
    background-image: none;
}
.carousel-control-next{
  background-image: none;
}


//forms styling
.contact-fieldset {
  margin: 20px 0px 50px;
  padding: 20px 20px 10px 20px;
  border: 1px solid #5B0428;
  border-radius: 10px;
}
form input.error, form textarea.error {
  border-color: #b94a48 !important;
  box-shadow: none;
}
label, input, button, select, textarea, input[type="text"] {
  font-size: 14px;
  color: #767676;
  font-weight: 400;
  /*line-height: 2.7em;*/
  text-shadow: 0px 1px 0px #fff;
}
label {
  width: 30%; /*increase width of label if longer label text, simuyltaneusoly decrease that of input & textarea*/
  padding-right: 1%;
  float: left;
  height: auto;
}
input[type="text"], select, textarea {
  width: 66% !important;
  height: 30px;
  padding: 5px;
  margin: 0;
  display: inline-block;
}
textarea {
  width: 66% !important;
  height: auto;
}
input[type="text"], input[type="email"], textarea, select {
  background-color: #fff;
  border: 1px solid #dadee1;
  box-shadow: none;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -webkit-transition: border linear 0.2s;
  -moz-transition: border linear 0.2s;
  -ms-transition: border linear 0.2s;
  -o-transition: border linear 0.2s;
  transition: border linear 0.2s;
}
textarea:focus, input[type="text"]:focus, select:focus {
  box-shadow: none;
}
.submit {
  background: #484a4b;
  padding: 10px 40px;
  margin-left: 31%; /*width of label + 1% (padding-right of label) */
  font-size: 16px;
  color: #fff !important;
  line-height: 16px;
  font-weight: 600;
  text-shadow: 0px 1px 0px #000;
  cursor: pointer;
  border: none;
  width: auto;
  height: auto;
  display: inline-block;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-box-shadow: 0 1px 0px 0px rgba(255, 255, 255, 0.2) inset;
  -webkit-box-shadow: 0 1px 0px 0px rgba(255, 255, 255, 0.2) inset;
  box-shadow: 0 1px 0px 0px rgba(255, 255, 255, 0.2) inset;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.submit:hover {
  background: #67B1E2;
}
#result {
  padding-left: 31%; /*width of label + 1% (padding-right of label) */
}
.antispam {
  display:none;
}
//forms styling ends
#header-btm {
  background: #e3e3e3;
  border-bottom: 1px solid #fff;
  padding: 40px 0px 15px 0px;
  margin-bottom: 30px;
}
.pricing-banner-front {
  background-color: #e3e3e3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.pricing-banner-txt, .pricing-banner-txt:hover, .pricing-banner-txt:visited, .pricing-banner-txt:link, .pricing-banner-txt:active {
  color: #000000 !important;
  text-shadow: none !important;
  text-decoration: none !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 1.3em !important;
  font-style: normal !important;
  margin-bottom: 15px !important;
}
.pricing-txt-notactive, .pricing-txt-notactive:visited, .pricing-txt-notactive:link, .pricing-txt-notactive:active {
  color: rgba(255,255,255, 0.5);
  text-shadow: none;
  text-decoration: none;
}
.txt-center {
  text-align: center !important;
}
.prodimg {
  max-width: 100%;
  height: auto;
}
.text-400{
    font-weight: 400;
}
.mapimg_sub{
  font-size: 14px;
}

#homepage-hero {
  background-color: #f8f8f8;
  background-size: cover;
  width: 100%;
  color: #000000;
  text-shadow: none;
}
.feature-list {
  margin-left: 20px;
  list-style-position: inside;
  list-style-type: disc;
}
.feature-list-secondary {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 20px;
}
.indent-bullet {
  padding-left: 25px;
}
.major-release {
  font-weight: bold;
}
.minor-release {
  font-weight: bold;
}
.sub-header {
  font-weight: bold;
}

.sub-header-2 {
  font-style: italic;
  list-style: none;
  margin-left: 20px;
}

.pricing-banner-grid-features {
  background-color: #e3e3e3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.HOMER-grid-logo {
  display: block;
  margin: 0 auto;
  padding-bottom: 10px;
}
@include media-breakpoint-up(md){
    .HOMER-grid-logo {
        display: block;
        margin: 0 auto;
        padding-bottom: 40px;
    }
}
.grid-box-heigth{
  min-height: 200px;
}
.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
@include media-breakpoint-up(md) {
  .vertical-line {
    border-left: 2px solid #5c004f;
  }
}
.quote-text {
  font-style: italic;
  color: #5c004f;
}
.dropdown-toggle::after {
  content: none;
}
.dropdown-menu a{
  text-decoration: none;
  color: #000000;
}
.dropdown-menu ul{
  list-style-type: none;
}
.modal-title{
  color: #5B0428;
}
.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
}
.fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object, .fluid-width-video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.accordion .card-header .btn-link {
  text-decoration: none;
}
#gridfeatures .card {
  border: none;
}
#gridfeatures .card-header{
  background-color: #ffffff;
}
#gridfeatures .card-body{
    background-color: #EEEEEE;
}

.pricing-banner-grid {
  height: 315px;
  background: url("/img/pricing-banner-image-grid.jpg") center center no-repeat !important;
  background-size: 100% !important;
  margin-bottom: -60px !important;
}
.biomass-sprite {
  background: url('/img/testme.svg') no-repeat 0 0;
  width: 96px;
  height: 96px;
}
.hydro-sprite {
  background: url('/img/testme.svg') no-repeat -475px -7px;
  width: 96px;
  height: 96px;
}
.combined-heat-sprite {
  background: url('/img/testme.svg') no-repeat -949px 0;
  width: 96px;
  height: 96px;
}
.advanced-load-sprite {
  background: url('/img/testme.svg') no-repeat -0px -418px;
  width: 96px;
  height: 96px;
}
.advnaced-grid-sprite {
  background: url('/img/testme.svg') no-repeat -475px -418px;
  width: 96px;
  height: 96px;
}
.hydrogen-sprite {
  background: url('/img/testme.svg') no-repeat -949px -418px;
  width: 96px;
  height: 96px;
}
.storage-sprite {
  background: url('/img/testme.svg') no-repeat 0 -838px;
  width: 96px;
  height: 96px;
}
.multi-year-sprite {
  background: url('/img/testme.svg') no-repeat -475px -857px;
  width: 96px;
  height: 96px;
}
.matlab-sprite {
  background: url('/img/testme.svg') no-repeat -949px -836px;
  width: 96px;
  height: 96px;
}

.header-testimonial {
  font-size: 20px !important;
  line-height: 1.2;
}
.prodproblockquotes {
  padding-left: 10px !important;
  border-left: 5px solid #5B0428;
  font-style: italic;
}
.prodproblockquotes a {
  text-decoration: none;
}
.prodprosubtitle1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 39.84px;
}
.prodprosubtitle2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 26.56px;
}
.list-checkmark li {
  padding-left: 20px;
  margin-bottom: 10px;
}
.large-testimonial{
  font-size: 24px !important;
  line-height: 1.3;
}
.testimonial {
  font-style: italic;
}
.testimonial a{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
@include media-breakpoint-up(md) {
  .vertbartest {
    border-left: 1px solid #5B0428;
  }
}
.prodpro_cta_block {
  background: #5B0428;
  font-size: .875rem;
  color: #fff;
  border-radius: 16px 16px 16px 16px;
}
.prodpro_cta_block a {
  color:#ffffff
}
.btn-download-homer-pro{
  background-image: none;
  border-color: #ffffff;
  border-width: medium;
}
.module-text{
  min-height: 160px;
}
.icon-small-bg {
  background: #5B0428;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-right: 10px;
  float: left;
}
.brochures-base a{
  text-decoration: none;
}
.brochures-base a:hover{
  text-decoration: underline #5B0428;
}
.training-banner {
  height: 240px;
  background-color: #e3e3e3;
  background-size: 100% !important;
}
.training-banner-individuals {
  height: 240px;
  background-color: #e3e3e3;
  background-size: 100% !important;
}
.banner-txt, .banner-txt:hover, .banner-txt:visited, .banner-txt:link, .banner-txt:active {
  color: #000000 !important;
  text-shadow: none !important;
  text-decoration: none !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 1.3em !important;
  font-style: normal !important;
  margin-bottom: 15px !important;
}
.ulRed{
    color: #5B0428;
}
.fixed-top {
  position: fixed;
  top: 41px;
  right: 0;
  left: 0;
  z-index: 1030;
}
.navbar-toggler {
  position: absolute;
  right: 25px;
  top: 15px;
@include media-breakpoint-down(sm) {
  right: 10px;
}

}
@include media-breakpoint-up(md) {
  .tightbody {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
}
.carousel-item em{
    font-style: italic;
    text-shadow: 0px 1px 0px #fff;
    color:#5a5a5a;
}
.carousel-item h3{
    font-size: 1.125em;
}
.carousel-item p{
  font-style: italic;
  text-shadow: 0px 1px 0px #fff;
  color:#5a5a5a;
}
.modal-dialog{
  margin-top: 18%;
}
#homepage-hero a{
  text-decoration: none;
}
#footer h5{
  color: #ffffff;
}
form label img{
  width: 14px;
}
@media (min-width: 768px) {
  .narrow-container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .narrow-container {
    max-width: 720px;
  }
}
@media (min-width: 1200px) {
  .narrow-container {
    max-width: 960px;
  }
}
@media (min-width: 1400px) {
  .narrow-container {
    max-width: 1140px;
  }
}

//test area for pricing pages

.pricing-nav-active {
  display: inline-block;
  border: 1px solid #ffffff;
  background-color: rgba(255,255,255,0.3);
  width: 150px;
  height: 40px;
  float: left;
  text-align: center;
}

.pricing-nav {
  display: inline-block;
  border: none;
  width: 150px;
  height: 40px;
  float: left;
  text-align: center;
}
.wrapper{
    text-align: center;
    position: relative;
    padding-bottom: 10%;
    padding-top: 25px;
    height: 0;
}
.wrapper ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  zoom: 1;
}
.pricing-col {
  position: relative;
  margin-top: 40px !important;
  background-color: #ffffff;
  box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.2);
}
.pricing-col a{
  text-decoration: none;
}
.pricing-col input[type="checkbox"]:checked{
  accent-color: #5B0428;
}
.pricing-col h6{
  font-size: .75rem;
  font-weight: 600;
}
.pricing-col h5{
  font-size: .88rem;
  font-weight: 600;
}
.blue-line {
  background: #5B0428;
  width: auto;
  height: 4px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.pricing-title{
  font-size: 1.25rem;
}
.pricing-title-lrg{
  font-size: 2.25rem;
  font-weight: 400;
  margin-bottom: 25px;
  margin-top: 20px;
  text-align: center;
  line-height: 1.2em;
}
.pricing-txt {
  margin-top: -12px;
  color: #666666;
  font-size: 1.5rem;
  font-weight: 400;
}
.pricing-btn-group {
  margin-left: 8px;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 45px;
}
.gap {
  display: block;
  width: 100%;
  height: 13px;
}
#gridfeatures .card {
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
}
#gridfeatures .card-header{
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  padding: .75rem 1.25rem;
}
#gridfeatures .card-body{
  padding: 1.25rem;
  background-color: #ffffff;
}
#gridfeatures>.card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
#gridfeatures>.card .card-header {
  margin-bottom: -1px;
}
.medium-rounded {
  border-radius: 30px;
}
.dark-box {
  background: #5B0428;
  padding: 20px 20px 25px 20px;
  text-shadow: 0px 1px 0px #000;
  text-align: center;
}
.HOMERblue{
    color: #5B0428;
}
.try-it {
  background: #ffffff;
  color: #5B0428;
  border: 2px solid #5B0428;
  transition: all 0.3s ease;
}
.price-btn {
  font-size: 18px;
  font-weight: 500;
  outline: 0;
  width: 100%;
  height: 34px;
  margin: 0 auto;
}
.buy-it {
  background: #5B0428;
  color: #ffffff;
  border: 2px solid #5B0428;
  transition: all 0.3s ease;
}
.ulta_cookiebanner{
  position: fixed;
  bottom: 0%;
  z-index: 9999;
  width: 100%;
}
input[type=email], select  {
  width: 66% !important;
  height: 30px;
  padding: 5px;
  margin: 0;
  display: inline-block;
}
.heading-icon img {
  margin-top: -10px;
}
.module-title{
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
}
